import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { analyticClickEvent } from "../../../components/googleAnalytics"
import { ReactAudioPlayer, ReactVideoPlayer } from "../../../components/reactPlayer"
import Layout from "../../../components/layout"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
} from "../../../styledComponents/videoTestimonial"
import { StyledButton } from "../../../styledComponents/button"
import { EventListSection } from "../../../styledComponents/eventList"
import { StyledLinkButton } from "../../../styledComponents/button"


const FhtjIdealWeightRecordings = ({ location }) => {

  // let products = data.allWpProduct.edges

  const [tabIndex, setTabIndex] = useState(0);

  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("session-1")) {
      setTabIndex(0)
    }
    if (urlParams.has("session-2")) {
      setTabIndex(1)
    }
  }

  useEffect(() => {
    checkIfHasQuery()
  }, [])

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    setIsWatchFullVersionOpen(value)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])

  return (
    <> <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
      <Layout displayNavBorder location={location} hideNewsletterSignUp>
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">IdealWeight Intelligent Energy Drops</h1>
          </StyledVideoTestimonialHeader>
          <EventListSection style={{ "padding": "0" }}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
              <TabList>
                <Tab>Call 1</Tab>
                <Tab>Call 2</Tab>
                
              </TabList>
              <TabPanel>
                {/* Session 1 */}
                <h2 style={{ 'text-align': 'center' }}>Tuesday 3rd September</h2>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-IdealWeight-Intelligent-Energy/live-calls/IdealWeight-Intelligent-Energy-Session-1.mp4"
                    title="IdealWeight Session 1"
                    playing={true}
                    download={true}
                  />
                    :
                    <ReactAudioPlayer
                      autoPlay={false}
                      audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-IdealWeight-Intelligent-Energy/live-calls/IdealWeight-Intelligent-Energy-Session-1.mp3"
                      onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: IdealWeight Session 1')}
                      title="IdealWeight Session 1"
                    />
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-IdealWeight-Intelligent-Energy/live-calls/IdealWeight-Intelligent-Energy-Session-1.mp3" download onClick={() => analyticClickEvent('click', "Download", 'IdealWeight Session 1')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
              </TabPanel>
              <TabPanel>
                {/* Session 2 */}
                <h2 style={{ 'text-align': 'center' }}>Thursday 5th September</h2>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-IdealWeight-Intelligent-Energy/live-calls/IdealWeight-Intelligent-Energy-Session-2.mp4"
                    title="IdealWeight Session 2"
                    onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: IdealWeight Session 2')}
                    playing={true}
                    download={true}
                  />
                    :
                    <>
                      <ReactAudioPlayer
                        autoPlay={false}
                        audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-IdealWeight-Intelligent-Energy/live-calls/IdealWeight-Intelligent-Energy-Session-2.mp3"
                        onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: IdealWeight Session 2')}
                        title="IdealWeight Session 2"
                      />

                    </>
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-IdealWeight-Intelligent-Energy/live-calls/IdealWeight-Intelligent-Energy-Session-2.mp3" download onClick={() => analyticClickEvent('click', "Download", 'IdealWeight Session 2')}>
                        <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z" /><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z" /><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z" /></g></svg>
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
              </TabPanel>
            </Tabs>
          </EventListSection>
        </StyledVideoTestimonialArticle>
        {/* <Section>
          <Heading level={1} className="hidden"> Audio Programs &amp; Meditations</Heading>
          <ProductList posts={products} />
        </Section> */}

      </Layout>
    </>
  )
}

export default FhtjIdealWeightRecordings

